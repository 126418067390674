import axios from 'axios';

export default {
    install: (app) => {
        const deviceConnection = axios.create({
            timeout: 60000,
            // headers: {
            //     'Content-Type': 'application/json',
            // },
        });

        deviceConnection.interceptors.response.use(response => {
            return response;
        }, error => {
            console.log(error.message);
            return Promise.resolve({
                data: {
                    code: "-1"
                }
            });
        });

        // 使 http 对象全局可用
        app.config.globalProperties.$deviceConnection = deviceConnection;
    }
}