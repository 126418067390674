import axios from 'axios';
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import {errorToast} from "@/utils/toastConfig";

export default {
    install: (app) => {
        const http = axios.create({
            baseURL: process.env.VUE_APP_BASE_API,
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        http.interceptors.request.use(config => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });

        http.interceptors.response.use(response => {
            if (response.data.status !== 1) {
                toast("错误: " + response.data.msg, errorToast);
            }
            return response.data.data;
        }, error => {
            if (error.response && error.response.status === 401) {
                window.location.href = '/login';
            }
            toast("网络连接错误", errorToast);
            console.log(error.message);
            return Promise.reject(error);
        });

        // 使 http 对象全局可用
        app.config.globalProperties.$http = http;
    }
}
