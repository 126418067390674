// toastConfig.js
export const successToast = {
    theme: "colored",
    type: "success",
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: true,
    transition: "slide",
    icon: true,
    closeButton: false,
    toastClassName: 'bg-success',
};

export const errorToast = {
    theme: "colored",
    type: "error",
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: true,
    transition: "slide",
    icon: true,
    closeButton: false,
    toastClassName: 'bg-danger',
};
