export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
    meta: {
      title: "登录",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (sessionStorage.getItem('uid')) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "退出", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.removeItem('token');
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/login/logout.vue")
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "首页",
      authRequired: true,
    },
    component: () => import("../views/homepage/home.vue"),
  },
  // 账户
  {
    path: "/account/balanceDetail",
    name: "account-balanceDetail",
    meta: { title: "点券明细", authRequired: true },
    component: () => import("../views/account/balanceDetail.vue"),
  },
  {
    path: "/account/recharge",
    name: "account-recharge",
    meta: { title: "点券充值", authRequired: true },
    component: () => import("../views/account/recharge.vue"),
  },
  {
    path: "/account/config",
    name: "account-config",
    meta: { title: "常用配置", authRequired: true },
    component: () => import("../views/account/config.vue"),
  },
  {
    path: "/account/manage",
    name: "account-manage",
    meta: { title: "账户管理", authRequired: true },
    component: () => import("../views/account/manage.vue"),
  },
  {
    path: "/account/password",
    name: "account-password",
    meta: { title: "修改密码", authRequired: true },
    component: () => import("../views/account/password.vue"),
  },
  // 日本
  {
    path: "/jpn/list",
    name: "jpn-list",
    meta: { title: "日本订单列表", authRequired: true },
    component: () => import("../views/jpn/list.vue"),
  },
  {
    path: "/jpn/detail/:id?",
    name: "jpn-detail",
    meta: { title: "日本签证详情", authRequired: true },
    component: () => import("../views/jpn/detail.vue"),
  },
  {
    path: "/jpn/eVisaSearch",
    name: "jpn-eVisaSearch",
    meta: { title: "日本电子签查询", authRequired: true },
    component: () => import("../views/jpn/eVisaSearch.vue"),
  },
  {
    path: "/jpn/eVisaDownload",
    name: "jpn-eVisaDownload",
    meta: { title: "日本电子签查询", authRequired: true },
    component: () => import("../views/jpn/eVisaDownload.vue"),
  },
  {
    path: "/jpn/csvDownload",
    name: "jpn-csvDownload",
    meta: { title: "日本3年/5年CSV下载", authRequired: true },
    component: () => import("../views/jpn/csvDownload.vue"),
  },
  {
    path: "/jpn/statistics",
    name: "jpn-statistics",
    meta: { title: "日本订单统计", authRequired: true },
    component: () => import("../views/jpn/statistics.vue"),
  },
  {
    path: "/jpn/dmc",
    name: "jpn-dmc",
    meta: { title: "日本地接社管理", authRequired: true },
    component: () => import("../views/jpn/DMC.vue"),
  },
  {
    path: "/viewPdf",
    name: "viewPdf",
    meta: { title: "预览PDF文件", authRequired: true },
    component: () => import("../views/jpn/viewPdf.vue"),
  },
  // 澳大利亚
  {
    path: "/au/list",
    name: "au-list",
    meta: { title: "澳大利亚订单列表", authRequired: true },
    component: () => import("../views/au/list.vue"),
  },
  {
    path: "/au/account",
    name: "au-account",
    meta: { title: "澳大利亚电子签账户", authRequired: true },
    component: () => import("../views/au/account.vue"),
  },
  {
    path: "/au/detail/:id?",
    name: "au-detail",
    meta: { title: "澳大利亚签证详情", authRequired: true },
    component: () => import("../views/au/detail.vue"),
  },
  // 英国
  {
    path: "/uk/list",
    name: "uk-list",
    meta: { title: "英国订单列表", authRequired: true },
    component: () => import("../views/uk/list.vue"),
  },
  {
    path: "/uk/detail/:id?",
    name: "uk-detail",
    meta: { title: "英国订单详情", authRequired: true },
    component: () => import("../views/uk/detail.vue"),
  },
];